import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

type ProductPageContextProps = {
  configuredVariantUrl: string
  setConfiguredVariantUrl: Dispatch<SetStateAction<string>>
  configuredVariantSku: string
  setConfiguredVariantSku: Dispatch<SetStateAction<string>>
  configurableUid: string
  setConfigurableUid: Dispatch<SetStateAction<string>>
}

export const ProductPageContext = createContext<ProductPageContextProps | undefined>(undefined)

export const useProductPageContext = () => {
  const context = useContext(ProductPageContext)
  if (context === undefined) {
    throw new Error('useProductPageContext must be used inside a ProductPageProvider')
  }
  return context
}

export function ProductPageContextProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const [configuredVariantUrl, setConfiguredVariantUrl] = useState('')
  const [configuredVariantSku, setConfiguredVariantSku] = useState('')
  const [configurableUid, setConfigurableUid] = useState('')
  const memoizedValue = useMemo(
    () => ({
      configuredVariantUrl,
      setConfiguredVariantUrl,
      configuredVariantSku,
      setConfiguredVariantSku,
      configurableUid,
      setConfigurableUid,
    }),
    [configurableUid, configuredVariantSku, configuredVariantUrl],
  )

  useEffect(() => {
    // Various components have been modified (such as Spec.tsx) so that when a configured variant is acheived, the components original props are ignored.
    // Initially, we want this. Because on a configurable page, the initial props are of the config product, which we want to update if we have a configured variant
    // However, when navigating away to another product (via search for example) we do want the original props of that product to be used, so we need to reset our context on route changes
    router.events.on('routeChangeStart', () => {
      setConfiguredVariantSku('')
      setConfigurableUid('')
      setConfiguredVariantUrl('')
    })
  }, [router.events])

  return <ProductPageContext.Provider value={memoizedValue}>{children}</ProductPageContext.Provider>
}

import { Image, ImageProps } from '@graphcommerce/image'
import { NextLink } from '@graphcommerce/next-ui'
import { Box, Container, Typography, Chip, useTheme } from '@mui/material'
import { setTitle, setTitleClean } from '../utils'
import { RowInspirationGridFragment } from './RowInspirationGrid.gql'

type InspirationGridItemProps = {
  url: string
  title?: string | null
  inpirationGridTitle?: string | null
  asset?: {
    url: string
    width?: number | null
    height?: number | null
    mimeType?: string | null
    size?: number | null
    alt?: string | null
  } | null
} & Pick<ImageProps, 'sizes'>

function InspirationGridItem(props: InspirationGridItemProps) {
  const { url, asset, title, inpirationGridTitle, sizes } = props

  if (!url || !asset) return null

  const itemTagUrl = url.split('/')[0]
  const itemTag =
    itemTagUrl === 'diy'
      ? itemTagUrl.toUpperCase()
      : itemTagUrl.replace(/^./, itemTagUrl[0].toUpperCase())

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <Box
        sx={{ position: 'absolute', width: '100%', height: '100%' }}
        href={url}
        component={NextLink}
      >
        <Image
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={asset.url}
          width={asset.width || 200}
          height={asset.height || 200}
          quality={75}
          alt={asset.alt ?? ''}
          sizes={sizes}
        />
        <Box
          sx={{
            background: 'linear-gradient(0deg, rgba(0,0,0,0.15) 20%, rgba(0,0,0,0) 100%)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          position: 'absolute',
          color: 'primary.contrastText',
          bottom: 0,
          left: 0,
          padding: theme.spacings.xs,
        })}
      >
        <Chip
          href={itemTagUrl}
          component={NextLink}
          label={itemTag}
          variant='filled'
          size='medium'
          color='default'
          sx={{ mb: 1 }}
        />
        <Typography
          component='p'
          variant='h5'
          sx={(theme) => ({
            color: 'primary.contrastText',
            [theme.breakpoints.up('xs')]: {
              lineHeight: '1.1 !important',
            },
            [theme.breakpoints.up('md')]: {
              lineHeight: '1.5 !important',
            },
          })}
          dangerouslySetInnerHTML={{
            __html: setTitleClean(inpirationGridTitle || title),
          }}
        />
      </Box>
    </Box>
  )
}

export function RowInspirationGrid(props: RowInspirationGridFragment) {
  const {
    title,
    rowInspirationGridItem1,
    rowInspirationGridItem2,
    rowInspirationGridItem3,
    rowInspirationGridItem4,
    rowInspirationGridItem5,
    rowInspirationGridItem6,
    rowInspirationGridItem7,
    rowInspirationGridItem8,
  } = props
  const t = useTheme()

  return (
    <Box sx={(theme) => ({ mb: `calc(${theme.spacings.xl} + ${theme.spacings.md})` })}>
      <Container>
        <Typography
          component='h2'
          variant='h1'
          align='center'
          sx={(theme) => ({
            marginBottom: theme.spacings.md,
            textTransform: 'uppercase',
          })}
          dangerouslySetInnerHTML={{
            __html: setTitle(title),
          }}
        />
        <Box
          sx={(theme) => ({
            display: 'grid',
            gap: theme.spacings.sm,
            aspectRatio: '2 / 6',
            gridTemplateAreas: `
            "item1 item1"
            "item1 item1"
            "item2 item3"
            "item4 item4"
            "item5 item6"
            "item7 item8"
          `,
            gridTemplateColumns: `repeat(2, 1fr)`,
            gridTemplateRows: 'repeat(6, 1fr)',

            [theme.breakpoints.up('sm')]: {
              aspectRatio: '3 / 3',
              gridTemplateAreas: `
              "item1 item2 item3"
              "item4 item4 item5"
              "item6 item7 item8"
            `,
              gridTemplateColumns: `repeat(3, 1fr)`,
              gridTemplateRows: 'repeat(3, 1fr)',
            },
            [theme.breakpoints.up('md')]: {
              aspectRatio: '5 / 3',
              gridTemplateAreas: `
              "item1 item2 item3 item3 item4"
              "item5 item5 item3 item3 item6"
              "item5 item5 item7 item8 item8"
            `,
              gridTemplateColumns: `repeat(5, 1fr)`,
              gridTemplateRows: 'repeat(3, 1fr)',
            },
          })}
        >
          <Box sx={{ gridArea: 'item1' }}>
            {rowInspirationGridItem1 && (
              <InspirationGridItem
                {...rowInspirationGridItem1}
                sizes={{
                  0: '100vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '20vw',
                  [t.breakpoints.values.xl]: '266px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item2' }}>
            {rowInspirationGridItem2 && (
              <InspirationGridItem
                {...rowInspirationGridItem2}
                sizes={{
                  0: '50vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '20vw',
                  [t.breakpoints.values.xl]: '266px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item3' }}>
            {rowInspirationGridItem3 && (
              <InspirationGridItem
                {...rowInspirationGridItem3}
                sizes={{
                  0: '50vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '40vw',
                  [t.breakpoints.values.xl]: '569px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item4' }}>
            {rowInspirationGridItem4 && (
              <InspirationGridItem
                {...rowInspirationGridItem4}
                sizes={{
                  0: '100vw',
                  [t.breakpoints.values.sm]: '66vw',
                  [t.breakpoints.values.md]: '20vw',
                  [t.breakpoints.values.xl]: '266px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item5' }}>
            {rowInspirationGridItem5 && (
              <InspirationGridItem
                {...rowInspirationGridItem5}
                sizes={{
                  0: '50vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '40vw',
                  [t.breakpoints.values.xl]: '569px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item6' }}>
            {rowInspirationGridItem6 && (
              <InspirationGridItem
                {...rowInspirationGridItem6}
                sizes={{
                  0: '50vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '20vw',
                  [t.breakpoints.values.xl]: '266px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item7' }}>
            {rowInspirationGridItem7 && (
              <InspirationGridItem
                {...rowInspirationGridItem7}
                sizes={{
                  0: '50vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '20vw',
                  [t.breakpoints.values.xl]: '266px',
                }}
              />
            )}
          </Box>
          <Box sx={{ gridArea: 'item8' }}>
            {rowInspirationGridItem8 && (
              <InspirationGridItem
                {...rowInspirationGridItem8}
                sizes={{
                  0: '50vw',
                  [t.breakpoints.values.sm]: '33vw',
                  [t.breakpoints.values.md]: '40vw',
                  [t.breakpoints.values.xl]: '569px',
                }}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export function setTitle(title) {
  const specialText = title.match(/\[\[(.*?)]]/)

  if (!specialText) return title

  const strippedSpecialText: string = specialText[0].replace(/[^a-z0-9]/gi, '')
  const isTextCapitalized = /^[A-Z]/.test(strippedSpecialText)
  const textClasses = isTextCapitalized ? 'allison allison-cap' : 'allison'

  return title.replace('[[', `<span class="${textClasses}">`).replace(']]', '</span>')
}

export function setTitleClean(title) {
  return title.replace('[[', '').replace(']]', '')
}
